header {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 272px;
	padding: 3.5rem 0;
	z-index: 10;
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#074003+0,000000+100&0.6+9,0+100 */
background: -moz-linear-gradient(top, rgba(38,74,19,0.7) 42%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, rgba(38,74,19,0.7) 42%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, rgba(38,74,19,0.7) 42%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#99074003', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */

	z-index: 10;

	@media(max-width: 992px) {
		position: relative;
		padding-top: 3rem;
		padding-bottom: 1rem;
		background: #325f1b;
		text-align: center;
	}

	.box-logo {
		@media(max-width: 765px) {
			text-align: left;
		}

		img {
			@media(max-width: 400px) {
				max-width: 150px;
			}
		}
	}

	.nav-social-networks {

		@media(max-width:  765px) {
			margin-bottom: 1rem;
		}

		& > ul {
			.reset-list;
			text-align: right;

			@media(max-width: 992px) {
				text-align: center;
				margin-top: 2rem;
			}

			& > li {
				.inline-block;
				vertical-align: middle;
				margin: 0 3.5rem;

				@media(max-width:  765px) {
					display: block;
					margin: 1rem 0;
				}

				& > ul {
					.reset-list;
					padding-bottom: .3rem;
					border-bottom: 1px solid @terceary-color;

					@media(max-width:  765px) {
						border-bottom: 0;
					}

					& > li {
						.inline-block;
						vertical-align: middle;
						margin: 0 1rem;

						& > a {
							display: block;
							color: @terceary-color;
							font-size: 1.7rem;

							&:hover {
								text-decoration: none;

								.fa {
									color: @primary-color;
								}
							}

							@media(max-width: 400px) {
								font-size: 1.5rem;

								.fa {
									font-size: 2.2rem;
								}
							}
						}

						&:first-child {
							margin-left: 0;
						}

						&:last-child {
							margin-right: 0;
						}
					}					
				}

				&:first-child {
					margin-left: 0;
				}

				&:last-child {
					margin-right: 0;
				}
			}

		}
	}

	.menu {
		position: relative;
		text-align: right;

		ul {
			.reset-list;

			li {
				.inline-block;
				margin: 2.5rem 1rem;
				text-align: center;

				@media(max-width:  1200px) {
					padding: 0;
					margin: 2.5rem .8rem;
				}

				a {
					display: block;
					text-transform: uppercase;
					color: @terceary-color;
					font-size: 1.7rem;
					font-weight: 600;

					&:after {
						content: "";
						display: block;
						width: 0;
						height: 2px;
						background: @terceary-color;
						.transition;

						@media(max-width:  765px) {
							display: none;
						}
					}

					@media(max-width:  1200px) {
						font-size: 1.5rem;
					}

					&:hover {
						text-decoration: none;

						&:after {
							width: 100%;
						}
					}
				}

				&:first-child {
					margin-left: 0;
				}

				&:last-child {
					margin-right: 0;
				}
			}
		}

		@media(max-width:  765px) {
			position: fixed;
			top: 0;
			right: -300px;
			width: 250px;
			height: 100%;
			padding: 2rem;
			overflow-y: auto;
			background: fade(@sixternary-color, 95); 
			z-index: 20;
			.transition;

			ul {
				
				li {
					display: block;
					margin: 1rem 0;

					li {
						display: block;
						padding: 0;
					}
				}
			}
		}
	}

	.btn-menu {
		position: relative;
		padding: .8rem 1.7rem;
		background: @terceary-color;
		border: 0;
		color: @primary-color;
		font-size: 2rem;
	}

	.btn-close {
		position: relative;
		padding: .5rem 1.5rem;
		background: @terceary-color;
		border: 0;
		color: @primary-color;
		font-size: 2rem;
	}
}

.seal {
	position: relative;

	.title {
		text-transform: uppercase;
		font-size: 1.8rem;
		color: @primary-color;
		font-weight: 700;

		@media(max-width: 992px) {
			font-size: 1.6rem;
		}
	}

	article {
		margin: 1.5rem 0;

		@media(max-width: 480px) {
			height: 140px;
		}
	}
}

.bg-video {
 	display: block;
    position: relative;
    width: 100%;
    height: auto;
}

body.navIsActive {

	@media(max-width: 765px) {
		.menu {
			right: 0;
		}
	}
}