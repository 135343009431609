footer {
	position: relative;
	margin-top: 4rem;
	padding: 4rem 0;
	background: @primary-color;

	@media(max-width: 992px) {
		text-align: center;
	}


	.text {
		color: @terceary-color;
		font-weight: 600;
	}

	h3 {
		.reset-list;
		margin-bottom: 1.5rem;
		color: @terceary-color;
		text-transform: uppercase;
		font-weight: 600;
	}

	.logo-footer {
		.inline-block;

		img {
			@media(max-width: 480px) {
				max-width: 180px;
			}
		}
	}

	.logo-footer,
	.text {
		margin: 4rem 0rem;

		@media(max-width: 992px) {
			margin: 2rem 0rem;
		}
	}

	.fb-page {
		border: 1px solid @terceary-color;
		
		@media(max-width: 992px) {
			margin: 2rem 0rem;
		}		
	}

	.logo-wf {
		float: right;
		margin-top: 2rem;


		@media(max-width: 992px) {
			margin-top: 0rem;
		}	
	}
}