@import "../../core/core.less";

@import "modules/header.less";
@import "modules/footer.less";

.slider-section {
	position: relative;
}

.featured {
	position: relative;
	top: -135px;
	margin-bottom: -125px;

	@media(max-width: 992px) {
		top: -90px;
		margin-bottom: -90px;
	}

	@media(max-width: 765px) {
		top: 0px;
		margin-bottom: 0px;
		padding-top: 2rem;
	}

	article {
		position: relative;
		overflow: hidden;
		margin: 1.5rem 0;

		.layer {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			padding: 1.5rem;
			background: fade(@primary-color, 85);
			text-align: center;
			.transition;

			img {
				max-width: 100px;
			}

			.title {
				.reset-list;
				margin: 1rem 0;
				font-weight: 600;
				color: @terceary-color;
				text-transform: uppercase;
				font-size: 1.8rem;
			}
		}

		&:hover  {
			.layer {
				left: -100%;
			}
		}
	}
}

.abouts {
	position: relative;
	padding: 4rem 0;

	.text {
		color: @primary-color;
		font-size: 2.3rem;
		font-weight: 700;
		text-transform: uppercase;

		@media(max-width: 765px) {
			font-size: 2rem;
		}
	}
}

.depositions-and-instagram {
	position: relative;
	padding-bottom: 1rem;

	.title-section {
		.reset-list;
		font-size: 2.5rem;
		color: @primary-color;
		margin-bottom: 4rem;

		&:after {
			display: block;
			content: "";
			width: 35px;
			height: 2px;
			margin-top: 1rem;
			background: @primary-color;
		}
	}

	.instagram {

		.subtitle {
			font-weight: 500;
			color: @sixternary-color;
		}

		img {
			padding: .25rem;
		}

		.more {
			.inline-block;
			.transition;
			float: right;
			margin: .5rem 0;
			padding: .5rem 1rem;
			font-weight: 400;
			border: 1px solid @primary-color;
			color: @primary-color;

			&:hover {
				background: @primary-color;
				color: @terceary-color;
				text-decoration: none;
			}
		}

		@media(max-width: 765px) {
			margin-top: 2rem;
		}
	}

	.depositions {

		.text {
			padding: 2rem;
			padding-bottom: 6rem;
			border: 1px solid fade(@primary-color, 80);
			color: @primary-color;
			font-size: 1.6rem;
		}

		.profile {
			position: relative;
			top: -40px;
			left: 0;
			padding: 0 2.5rem;

			img,
			.info {
				.inline-block;
				vertical-align: bottom;
			}

			.name {
				.reset-list;
				font-size: 1.6rem;
			}

			.info {
				margin-left: 1.5rem;
			}

			.name,
			.address {
				color: @primary-color;
			}

			.address {
				font-size: 1.3rem;
			}
		}

		.slick-dots {
			text-align: left;
			bottom: 0;

			li {
				
				button {
					&:before {
						font-size: 11px;
					}
				}

				&:first-child {
					margin-left: 0;
				}
			}
		}
	}
}

.photos {
	position: relative;
	padding: 4rem 0;

	.title-section {
		.reset-list;
		font-size: 2.5rem;
		color: @primary-color;
		margin-bottom: 4rem;

		&:after {
			display: block;
			content: "";
			width: 35px;
			height: 2px;
			margin-top: 1rem;
			background: @primary-color;
		}
	}

	figure {
		position: relative;
		padding: .1rem;

		.layer {
			display: none;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			color: @terceary-color;
			font-size: 2rem;
			text-align: center;
		}

		&:hover {
		 	.layer {
		 		display: block;
		 	}
		}
	}
}